<template>
  <v-layout id="acciones-permisos" v-if="!clonando && permisoAccion">
    <v-card v-bind="ent_permisoAccion">
      <!-- Título -->
      <v-card-title class="titulo">{{
        ent_header_permisoAccion.titulo
      }}</v-card-title>

      <!-- Listado de Botones -->
      <v-card-actions class="listado-botones">
        <v-chip
          v-bind="ent_chips_permisoAccion"
          v-for="(btn, index) in acciones"
          :key="index"
          class="chip"
          :color="setcolor_permisoAccion(index)"
          @click="clickBtn(index)"
        >
          <v-icon class="icono" small>{{
            seticon_permisoAccion(index)
          }}</v-icon>
          {{ acciones[index] }}
        </v-chip>
      </v-card-actions>
    </v-card>
  </v-layout>
</template>

<script>
export default {
  props: ["cfg", "acciones", "permisoAccion", "clonando"],
  data() {
    return {
      // variables de configuración
      ent_permisoAccion: this.cfg,
      ent_header_permisoAccion: this.cfg.header,
      ent_chips_permisoAccion: this.cfg.chips_permisoAccion
    };
  },

  methods: {
    setcolor_permisoAccion(index) {
      // establezo color de fondo
      if (this.permisoAccion[index] === "0")
        return this.ent_chips_permisoAccion.colorInactive;
      return this.ent_chips_permisoAccion.colorActive;
    },

    seticon_permisoAccion(index) {
      if (this.permisoAccion[index] === "0")
        return this.ent_chips_permisoAccion.iconInactive;
      return this.ent_chips_permisoAccion.iconActive;
    },

    clickBtn(index) {
      this.permisoAccion.splice(
        index,
        1,
        this.permisoAccion[index] === "0" ? "1" : "0"
      );
    }
  }
};
</script>

<style scope>
#acciones-permisos .listado-botones .icono {
  padding-right: 5px;
}
#acciones-permisos .listado-botones .chip {
  margin-right: 10px;
}
</style>
